<template>
  <v-data-table
    v-model="selectedFacilities"
    must-sort
    fixed-header
    show-expand
    single-expand
    item-key="uuid"
    :loading="loading"
    :headers="tableHeaders"
    :items="facilities"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 25, 75, 150 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('facility', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    class="elevation-2"
    :show-select="$auth.user().client ? $auth.user().client.services.map(x => x.name).includes('ASP Reports') : true"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3"
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col
          v-if="!$auth.user().client"
          md="2"
        >
          <tag-filter
            key="facility-filter"
            :value.sync="options.filter.tags"
            type="facility"
          />
        </v-col>
        <v-col
          v-if="$auth.check({ clients: ['edit', 'view'] })"
          md="2"
        >
          <client-filter
            key="client-filter"
            :value.sync="options.filter.client"
            :params="{ has: 'facilities' }"
          />
        </v-col>
      </v-row>
    </template>

    <template
      v-if="
        $auth.check({ reports: ['order'] }) &&
          ($auth.user().client ? $auth.user().client.services.map(x => x.name).includes('ASP Reports') : true)
      "
      #footer.prepend
    >
      <monthly-report
        v-if="selectedFacilities && selectedFacilities.length"
        :record="selectedFacilities"
        title="Order ASP Report"
        :subtitle="selectedFacilities.length | pluralize(['facility', 'facilities'], { includeNumber: true })"
        color="info"
        :multi="selectedFacilities.length > 1"
        :btn-disabled="selectedFacilities.length > limitSelectFacilities"
        :msg-error="`You can only submit a maximum of ${limitSelectFacilities} facilities`"
        :coxnsolidated-disabled="selectedFacilities.length <= 1"
        class="mt-n2"
        show-redacted
        button
        range
        @action="monthlyReport"
      >
        <template #action-button>
          <v-icon
            small
            class="mr-2"
          >
            fal fa-file-chart-column
          </v-icon>
          {{ $t('Submit') }} Order
        </template>
      </monthly-report>
    </template>

    <template #header.data-table-select="{ on, props }">
      <v-simple-checkbox
        v-bind="props"
        :disabled="selectedFacilities.length >= limitSelectFacilities"
        v-on="on"
      />
    </template>

    <template #item.data-table-select="{ isSelected, select }">
      <v-simple-checkbox
        :value="isSelected"
        :disabled="!isSelected && selectedFacilities.length >= limitSelectFacilities"
        @input="select($event)"
      />
    </template>


    <template #expanded-item="{ item }">
      <td :colspan="tableHeaders.length">
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>UUID</v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.uuid }}
                  <v-btn
                    small
                    icon
                    tile
                    color="info"
                    title="Copy"
                    @click="$clipboard(item.uuid)"
                  >
                    <v-icon small>
                      mdi-content-copy mdi-flip-h
                    </v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </td>
    </template>

    <template #item.client="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-avatar
            size="30"
            tile
            v-on="on"
          >
            <v-img :src="item.client.icon" />
          </v-avatar>
        </template>
        {{ item.client.name }}
      </v-tooltip>
    </template>

    <template #item.patients_count="{ item }">
      {{ item.patients_count }}
    </template>

    <template #item.lab_results_count="{ item }">
      <router-link :to="{ name: 'Lab Results', query: { facility: item.uuid } }">
        {{ item.lab_results_count }}
      </router-link>
    </template>

    <template #item.prescriptions_count="{ item }">
      <router-link :to="{ name: 'Prescriptions', query: { facility: item.uuid } }">
        {{ item.prescriptions_count }}
      </router-link>
    </template>

    <template #item.tags="{ item }">
      <v-chip
        v-for="tag in item.tags"
        :key="tag.id"
        small
        class="mr-1"
        v-text="tag.name.en"
      />
    </template>

    <template #item.actions="{ item }">
      <v-menu
        bottom
        right
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            small
            ripple
            icon
            plain
            v-on="on"
          >
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list
          class="py-0"
          dense
        >
          <v-list-item
            v-if="
              $auth.check({ reports: ['order'] }) &&
                ($auth.user().client ? $auth.user().client.services.map(x => x.name).includes('ASP Reports') : true)
            "
            link
          >
            <monthly-report
              :key="item.uuid"
              title="Order ASP Report"
              :record="item"
              show-redacted
              range
              allow-subscription
              @action="monthlyReport"
            >
              <template #action-button>
                <v-icon
                  small
                  class="mr-2"
                >
                  fal fa-file-chart-column
                </v-icon>
                {{ $t('Submit') }} Order
              </template>
            </monthly-report>
          </v-list-item>
          <v-list-item
            v-if="item.tags && item.tags.some(x => x.name.en == 'Precision ASP')"
            @click="precisionAsp(item)"
          >
            <v-list-item-action class="mr-4">
              <v-icon small>
                fal fa-file-excel fa-fw
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Precision ASP Master List </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="$auth.check({ facilities: 'edit' })" />
          <v-list-item
            v-if="$auth.check({ facilities: 'edit' })"
            @click="$emit('toggle-right-drawer', item, true)"
          >
            <v-list-item-action class="mr-4">
              <v-icon small>
                fa-kit fa-light-hospital-pen fa-fw
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Edit Facility </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>
<script>
export default {
  components: {
    SearchBar: () => import("@/components/tables/filters/SearchBar.vue"),
    ClientFilter: () => import("@/components/tables/filters/ClientFilter.vue"),
    TagFilter: () => import("@/components/tables/filters/TagFilter.vue"),
    MonthlyReport: () => import("@/components/tables/dialogs/MonthlyReport.vue"),
  },
  props: {
    search: {
      type: String,
      default: null,
    },
  },
  metaInfo () {
    return {
      title: this.$options.filters.capitalize(this.$tc('facility', 2)),
    }
  },
  data() {
    return {
      monthlyReportDialog: {
        open: false,
        menu: null,
        date: this.$moment().format("Y-MM"),
      },
      loading: false,
      detailDialog: {},
      options: {
        search: null,
        filter: this.$route.query,
      },
      totalItems: null,
      facilities: [],
      selectedFacilities: [],
      limitSelectFacilities: 20,
    };
  },
  computed: {
    tableHeaders() {
      var headers = [
        {
          text: this.$options.filters.capitalize(this.$t('name')),
          value: "name",
        },
        {
          text: this.$options.filters.capitalize(this.$tc('client', 2)),
          value: "client",
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(this.$t('address')),
          value: "address",
        },
        {
          text: this.$options.filters.capitalize(this.$t('city')),
          value: "city",
        },
        {
          text: this.$options.filters.capitalize(this.$t('state')),
          value: "state",
        },
        {
          text: "ZIP",
          value: "zip",
        },
        {
          text: this.$options.filters.capitalize(this.$tc('patient', 2)),
          value: "patients_count",
        },
        {
          text: this.$options.filters.capitalize(this.$tc('Lab Result', 2)),
          value: "lab_results_count",
        },
        {
          text: this.$options.filters.capitalize(this.$tc('prescription', 2)),
          value: "prescriptions_count",
        },
        {
          text: this.$options.filters.capitalize(this.$tc('tag', 2)),
          value: "tags",
          sortable: false,
        },
        {
          sortable: false,
          align: "right",
          value: "actions",
        },
      ]
      if (!this.$auth.check({ clients: ["view", "edit"] })) {
        headers = headers.filter((x) => x.value && !['client','tags'].includes(x.value));
      }
      return headers;
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchFacilities();
      },
    },
  },
  mounted() {
    this.$root.$on("fetch-facilities", (data) => {
      this.fetchFacilities(data);
    });
  },
  methods: {
    fetchFacilities() {
      this.loading = true;
      const promise = this.axios.get("facilities", {
        params: {
          ...{
            page: this.options.page,
            count: this.options.itemsPerPage,
            sort: this.options.sortBy[0],
            order: this.options.sortDesc[0] ? "desc" : "asc",
            search: this.options.search,
          },
          ...this.$route.query,
        },
      });

      return promise
        .then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchFacilities()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total;
          this.facilities = response.data.data;
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    monthlyReport(facility, month, redacted, consolidated, subscribe, recipients) {
      this.monthlyReportDialog.open = false;

      let count = 1

      let params = {
        start: this.$moment(Array.isArray(month) ? month[0] : month).startOf("month").format(),
        end: this.$moment(Array.isArray(month) ? month[1] : month).endOf("month").format(),
        consolidated: consolidated,
        redacted: redacted,
        subscribe: subscribe,
        recipients: recipients,
      }

      if (Array.isArray(facility)) {
        if (facility.length > 1) {
          params.facilities = facility.map(x => x.uuid)
          count = params.facilities.length
        } else {
          params.facility = facility[0]?.uuid
        }
      } else {
        params.facility = facility.uuid
      }

      consolidated = false
      redacted = false
      recipients = []
      subscribe = false

      this.$toast.processing(`Requesting ${ this.$options.filters.pluralize(count, 'report', { includeNumber: true }) }`);
      const toast = this.$toast.getCmp();

      this.axios({
        url: "/reports/monthlyReview/pdf",
        data: params,
        method: "POST",
        responseType: "blob",
      })
        .then(() => {
          toast.close();
          this.$toast.success(`Report generation in progress. You will receive an email when the ${ this.$options.filters.pluralize(count, 'report') } ${ this.$options.filters.pluralize(count, ['is', 'are']) } ready for download.`);
        })
        .catch((error) => {
          toast.close();
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        });
    },
    // monthlyMultiReport(month, redacted, consolidated) {
    //   this.monthlyReportDialog.open = false

    //   let message = "";
    //   if (this.selectedFacilities.length == 1){
    //     message = "Requesting Report for " + this.selectedFacilities[0]?.name;
    //   } else {
    //     message = consolidated ? "Requesting Consolidated Report" : "Requesting Multiple Reports";
    //   }
    //   this.$toast.processing(message);
    //   const toast = this.$toast.getCmp();

    //   this.axios({
    //     url: "/reports/monthlyReviewByFacilities/pdf",
    //     data: {
    //       facilities: this.selectedFacilities.map(x => x.uuid),
    //       start: this.$moment(Array.isArray(month) ? month[0] : month).startOf("month").format(),
    //       end: this.$moment(Array.isArray(month) ? month[1] : month).endOf("month").format(),
    //       redacted: redacted,
    //       consolidated: consolidated,
    //     },
    //     method: "POST",
    //     responseType: consolidated ? "blob" : "json",
    //   })
    //     .then(() => {
    //       toast.close();
    //       if (consolidated == true) {
    //         this.$toast.success("Report in progress. You will receive an email when the report is ready for download.");
    //       } else {
    //         this.$toast.success("Report in progress. You will receive an email when the reports are ready for download.");
    //       }
    //     })
    //     .catch((error) => {
    //       toast.close();
    //       this.$toast.error(error);
    //     });
    // },
    precisionAsp(facility) {
      this.$toast.processing("Generating report for " + facility.name);
      const toast = this.$toast.getCmp();
      this.axios({
        url: "/reports/asp/xls",
        params: {
          facility: facility.uuid,
        },
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          // link.setAttribute('download', ' - Arkstone ASP Monthly Report - ' + this.$moment().format('MMM Y') + '.xlsx')
          link.setAttribute(
            "download",
            "ASP - " +
              facility.name +
              " - " +
              this.$moment().format('LL') +
              ".xlsx",
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.close();
          this.$toast.success("Report generated");
        })
        .catch((error) => {
          toast.close();
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        });
    },
  },
};
</script>

<style scoped>
.row-asp-header{
  margin-top: 0;
}
</style>
